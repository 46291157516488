export const universitiesData=[
    {
        logo:"",
        name:"University of Johannesburg",
        openingDate:"1 April",
        closingDate:"31 October",
        isOpen:false,
        prospectorsLink:"https://www.uj.ac.za/wp-content/uploads/2023/07/uj-undergraduate-prospectus-2024.pdf",
        applicationFee:0,
        homePage:"https://www.uj.ac.za/",
        link:"https://www.uj.ac.za/admission-aid/undergraduate/",
        province:"Guateng",
        abbrev:"uj",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of the Free State",
        openingDate:"1 April",
        closingDate:"30 September",
        isOpen:true,
        prospectorsLink:"https://www.ufs.ac.za/docs/librariesprovider44/study-at-the-ufs/ufs-undergrad-prospectus-2025---future-focused.pdf?sfvrsn=9fda0c20_2",
        applicationFee:0,
        homePage:"www.ufs.ac.za",
        link:"https://apply.ufs.ac.za/Application/Start",
        province:"Free State",
        abbrev:"ufs",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of Mpumalanga",
        openingDate:"01 June",
        closingDate:"30 November",
        isOpen:true,
        prospectorsLink:"https://www.ump.ac.za/getattachment/Study-with-us/Application-Process/Online-Applications/Undergraduate-Programmes.pdf.aspx?lang=en-US",
        applicationFee:200,
        homePage:"",
        link:"https://www.ump.ac.za/Study-with-us/Application-Process/Online-Applications.aspx",
        province:"Mpumalanga",
        abbrev:"ump",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of Pretoria",
        openingDate:"02 April",
        closingDate:"",
        isOpen:false,
        prospectorsLink:"https://www.up.ac.za/media/shared/368/Faculty%20Brochures/2025/up_ug-prospectus-2025_nsc-ieb_devv5_web.zp246017.pdf",
        applicationFee:300,
        homePage:"",
        link:"https://www.up.ac.za/online-application",
        province:"Guateng",
        abbrev:"up",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"North-West University(NWU)",
        openingDate:" 1 March",
        closingDate:"30 September",
        isOpen:true,
        prospectorsLink:"https://studies.nwu.ac.za/sites/studies.nwu.ac.za/files/files/undergrad/2022/nwu-2023-Prospectus-ENG-web.pdf",
        applicationFee:0,
        homePage:"",
        link:"https://studies.nwu.ac.za/studies/how-apply-online",
        province:"North West",
        abbrev:"nwu",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of South Africa(UNISA)",
        openingDate:"1 September",
        closingDate:"13 October",
        isOpen:true,
        prospectorsLink:"",
        applicationFee:135,
        homePage:"",
        link:"https://www.unisa.ac.za/sites/corporate/default/Apply-for-admission",
        province:"Westen Cape",
        abbrev:"unisa",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of Limpopo(UL)",
        openingDate:"1 April",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.ul.ac.za/application/downloads/Undergraduate_Prospectus_2025.pdf",
        applicationFee:200,
        homePage:"",
        link:"https://www.ul.ac.za/index.php?Entity=Apply%20Now#start",
        province:"Limpopo",
        abbrev:"ul",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    
    {
        name:"University of Venda(Univenda)",
        openingDate:"20 July",
        closingDate:"31 December",
        isOpen:true,
        prospectorsLink:"https://www.univen.ac.za/wp-content/uploads/2024/05/2025_-Undergraduate-Student-Information-Brochure-corrected.pdf",
        applicationFee:100,
        homePage:"",
        link:"https://www.univen.ac.za/students/student-support-services/how-to-apply/apply-online/",
        province:"Limpopo",
        abbrev:"univen",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of Cape Town(UCT)",
        openingDate:"3 April",
        closingDate:"4 August",
        isOpen:true,
        prospectorsLink:"https://www.uct.ac.za/sites/default/files/media/documents/uct_ac_za/49/2025_ug_prospectus.pdf",
        applicationFee:100,
        homePage:"",
        link:"https://publicaccess.uct.ac.za/psc/public/EMPLOYEE/SA/c/NUI_FRAMEWORK.PT_LANDINGPAGE.GBL?LP=UCT_ONLINE_APP_PUBLIC&",
        province:"Westen Cape",
        abbrev:"uct",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Stellenbonch University",
        openingDate:"3 April",
        closingDate:"31 July",
        isOpen:true,
        prospectorsLink:"",
        applicationFee:100,
        homePage:"http://www.sun.ac.za/english",
        link:"https://student.sun.ac.za/signup/",
        province:"Westen Cape",
        abbrev:"sun",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of the Witwatersrand",
        openingDate:"1 April",
        closingDate:"30 June",
        isOpen:true,
        prospectorsLink:"https://issuu.com/witsmarketing/docs/2025_guide_for_undergrad_applicants",
        applicationFee:100,
        homePage:"https://www.wits.ac.za/",
        link:"https://self-service.wits.ac.za/psc/csprodonl/UW_SELF_SERVICE/SA/c/VC_OA_LOGIN_MENU.VC_OA_LOGIN_FL.GBL?&",
        province:"Guateng",
        abbrev:"wits",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of Kwazulu Natal(UKZN)",
        openingDate:"",
        closingDate:"30 June 2023(Medicine) & 30 September 2023 (Other programmes)",
        isOpen:true,
        prospectorsLink:"https://applications.ukzn.ac.za/wp-content/uploads/2024/02/Undergrad2024-WEB-updated-1.pdf",
        applicationFee:250,
        homePage:"",
        link:"https://www.cao.ac.za/IDLookup.aspx",
        province:"Kwazulu Natal",
        abbrev:"ukzn",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Mangosuthu University of Technology",
        openingDate:"",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.mut.ac.za/wp-content/uploads/2023/12/Archive.zip",
        applicationFee:250,
        homePage:"https://www.mut.ac.za/",
        link:"https://www.cao.ac.za/IDLookup.aspx",
        province:"Kwazulu Natal",
        abbrev:"mut",
        facebookPage:"https://www.facebook.com/MUTUmlazi/",
        linkedInPage:"https://www.linkedin.com/school/mangosuthu-university-of-technology/",
        twitterPage:"https://twitter.com/MUTcampus"
    },
    {
        name:"University of the Western Cape",
        openingDate:"13 May",
        closingDate:"30 September",
        isOpen:true,
        prospectorsLink:"https://www.uwc.ac.za/study/all-areas-of-study/departments",
        applicationFee:0,
        homePage:"",
        link:"https://www.uwc.ac.za/apply-now",
        province:"Westen Cape",
        abbrev:"uwc",
        facebookPage:"https://www.facebook.com/pages/UWC-Online/194989287319013",
        linkedInPage:"https://www.linkedin.com/school/uwc/",
        twitterPage:"https://twitter.com/UWCONLINE"
    },
    {
        name:"Rhodes University",
        openingDate:"",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.ru.ac.za/admissiongateway/application/entryrequirements/",
        applicationFee:100,
        homePage:"",
        link:"https://ross.ru.ac.za/ugadmissions",
        province:"Eastern Cape",
        abbrev:"ru",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Nelson Mandela Metropolitan University(NMMU)",
        openingDate:"",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.bing.com/ck/a?!&&p=594e3fa2a265e6f0JmltdHM9MTcyMDc0MjQwMCZpZ3VpZD0wMzc5Mzk2Ni02MjlhLTYyZjctMTVkOC0yZDMxNjNiOTYzNDAmaW5zaWQ9NTE5MA&ptn=3&ver=2&hsh=3&fclid=03793966-629a-62f7-15d8-2d3163b96340&psq=nelson+mandela+university+prospectus+2024&u=a1aHR0cHM6Ly93d3cubWFuZGVsYS5hYy56YS9nZXRtZWRpYS9jOTRhMzE0Ny02ZTU1LTQwOTgtYmMxYS0wMjZmOGY4MDdmMmQvTWFuZGVsYS1VbmktVW5kZXJncmFkLUd1aWRlP2Rpc3Bvc2l0aW9uPWF0dGFjaG1lbnQ&ntb=1",
        applicationFee:0,
        homePage:"",
        link:"https://applyonline.mandela.ac.za/Step1New.aspx",
        province:"Eastern Cape",
        abbrev:"nmmu",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Cape Peninsula University of Technology(CPUT)",
        openingDate:"",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.cput.ac.za/academic/prospectus",
        applicationFee:0,
        homePage:"",
        link:"https://www.cput.ac.za/study/apply/step-4-online-application",
        province:"Westen Cape",
        abbrev:"cput",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Durban University of Technology(DUT)",
        openingDate:"",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.dut.ac.za/wp-content/uploads/prospective_and_current_students/entry_requirements.pdf",
        applicationFee:0,
        homePage:"https://www.dut.ac.za/",
        link:"https://www.cao.ac.za/IDLookup.aspx",
        province:"Kwazulu Natal",
        abbrev:"dut",
        facebookPage:"https://www.facebook.com/DurbanUniversityofTechnology",
        linkedInPage:"",
        twitterPage:"https://twitter.com/dut_tweets"
    },
    {
        name:"University of Zululand(Unizulu)",
        openingDate:"",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.unizulu.ac.za/wp-content/uploads/2024/06/INFORMATION-BROCHURE-FOR-UNDERGRADUATE-STUDENTS.pdf",
        applicationFee:0,
        homePage:"",
        link:"https://www.cao.ac.za/IDLookup.aspx",
        province:"Kwazulu Natal",
        abbrev:"unizulu",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Vaal University of Technology(VUT)",
        openingDate:"1 April",
        closingDate:"30 Sepetember",
        isOpen:true,
        prospectorsLink:"https://www.vut.ac.za/wp-content/uploads/2024/02/2025-Undergrad-A4-flyer-double-sided-Final-for-Web.pdf",
        applicationFee:0,
        homePage:"",
        link:"https://ienablerprod.vut.ac.za/pls/prodi41/w99pkg.mi_login",
        province:"Guateng",
        abbrev:"vut",
        facebookPage:"https://facebook.com/vaaluniversityoftechnology",
        linkedInPage:"https://www.linkedin.com/company/vaal-university-of-technology-vut/",
        twitterPage:"https://twitter.com/VUT_Online/"
    },
    {
        name:"Tshwane University of Technology(TUT)",
        openingDate:"1 April",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.tut.ac.za/media/docs/2025%20Course%20Information.pdf",
        applicationFee:0,
        homePage:"",
        link:"https://applications-prod.tut.ac.za/",
        province:"Guateng",
        abbrev:"tut",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Central University of Technology(CUT)",
        openingDate:"1 April",
        closingDate:"",
        isOpen:true,
        prospectorsLink:"https://www.cut.ac.za/programmes-offered/",
        applicationFee:0,
        homePage:"",
        link:"https://enroll.cut.ac.za/pls/prodi41/w99pkg.mi_login",
        province:"Free State",
        abbrev:"cut",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"University of Fort Hare(ufh)",
        openingDate:"15 June",
        closingDate:"30 September",
        isOpen:true,
        prospectorsLink:"https://www.ufh.ac.za/apply/Study_at_Fort_Hare_2023.pdf",
        applicationFee:120,
        homePage:"https://www.ufh.ac.za/",
        link:"https://www.ufh.ac.za/dev/apply",
        province:"Eastern Cape",
        abbrev:"ufh",
        facebookPage:"http://www.facebook.com/ufh1916",
        linkedInPage:"https://www.linkedin.com/school/university-of-fort-hare",
        twitterPage:"http://www.twitter.com/ufh1916"
    },
    {
        name:"Sol Plaatje University",
        openingDate:"15 June",
        closingDate:"30 September",
        isOpen:true,
        prospectorsLink:"https://www.spu.ac.za/wp-content/uploads/2024/04/SPU-2025-UG-Prospectus.pdf",
        applicationFee:0,
        homePage:"https://www.spu.ac.za/",
        link:"https://ienabler.spu.ac.za/pls/prodi41/w99pkg.mi_login?numtype=S",
        province:"Northen Cape",
        abbrev:"spu",
        facebookPage:"https://www.facebook.com/SolPlaatjeUniv/",
        linkedInPage:"https://www.linkedin.com/company/sol-plaatje-university/",
        twitterPage:"https://twitter.com/MySPU"
    },
    {
        name:"Walter Sisulu University",
        openingDate:"1 June",
        closingDate:"30 September",
        isOpen:true,
        prospectorsLink:"https://www.wsu.ac.za/index.php/en/study-with-us/2024-prospectuses?layout=table",
        applicationFee:0,
        homePage:"https://www.wsu.ac.za/index.php/en/",
        link:"https://applications.wsu.ac.za/",
        province:"Eastern Cape",
        abbrev:"wsu",
        facebookPage:"https://www.facebook.com/OfficialWSU/",
        linkedInPage:"https://za.linkedin.com/school/wsu-walter-sisulu-university/",
        twitterPage:"https://x.com/WalterSisuluUni",
        whatsappPage:"https://whatsapp.com/channel/0029VaAgV50EquiLRlqa0D0A"
    },
]