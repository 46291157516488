import SasolLogo from "../../../assets/images/sasol_logo.png";

export const companiesData = [
    {
        name:"Sasol - Mine",
        logoImage:SasolLogo,
        homePageLink:"https://www.sasol.com/",
        vacanciesLink:"https://jobs.sasol.com/search/?createNewAlert=false&q=&optionsFacetsDD_location=&optionsFacetsDD_customfield2=&optionsFacetsDD_customfield3=",
        bursariesLink:"",
        facebookPage:"https://www.facebook.com/SasolLTD/",
        linkedInPage:"http://www.linkedin.com/company/sasol",
        twitterPage:"https://twitter.com/sasolsa"
    },
    {
        name:"Steinmuller Africa",
        logoImage:SasolLogo,
        homePageLink:"https://www.bilfinger.com/en/za",
        vacanciesLink:"https://steinmullerafrica.simplify.hr/#currentVacanciesContainer",
        bursariesLink:"",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    },
    {
        name:"Glencore",
        logoImage:"",
        homePageLink:"https://www.glencore.com/",
        vacanciesLink:"https://www.glencore.com/careers/career-opportunities/africa",
        bursariesLink:"",
        facebookPage:"http://www.facebook.com/glencore/",
        linkedInPage:"http://www.linkedin.com/company/8518",
        twitterPage:"http://www.twitter.com/glencore"
    },
    {
        name:"Thugela - Mine",
        logoImage:"",
        homePageLink:"https://www.thungela.com/",
        vacanciesLink:"https://careers.smartrecruiters.com/Thungela",
        bursariesLink:"https://www.thungela.com/careers/bursary-opportunities",
        facebookPage:"",
        linkedInPage:"https://www.linkedin.com/company/74309163/admin/",
        twitterPage:""
    }
    ,
    {
        name:"Eskom",
        logoImage:"",
        homePageLink:"https://www.eskom.co.za/",
        vacanciesLink:"https://secapps.eskom.co.za/sites/recruitment/_layouts/eskomauth/register.aspx?ReturnUrl=https%3A%2F%2Fsecapps.eskom.co.za%2Fsites%2Frecruitment%2FPages%2FMyDetails.aspx",
        bursariesLink:"",
        facebookPage:"https://www.facebook.com/EskomSouthAfrica",
        linkedInPage:"",
        twitterPage:"https://twitter.com/Eskom_SA"
    }
    ,
    {
        name:"Anglo American - Mine",
        logoImage:"",
        homePageLink:"https://www.angloamerican.com/",
        vacanciesLink:"https://www.angloamerican.com/careers/job-opportunities/apply",
        bursariesLink:"",
        facebookPage:"https://www.facebook.com/angloamerican",
        linkedInPage:"https://www.linkedin.com/company/anglo-american",
        twitterPage:"https://twitter.com/angloamerican"
    }
    ,
    {
        name:"Amerys - Mine",
        logoImage:"",
        homePageLink:"https://www.imerys.com/minerals",
        vacanciesLink:"https://www.imerys.com/careers/jobs?f%5B0%5D=all_jobs_country%3ASouth%20Africa",
        bursariesLink:"",
        facebookPage:"",
        linkedInPage:"https://www.linkedin.com/company/imerys/posts/",
        twitterPage:""
    }
    ,
    {
        name:"Transnet",
        logoImage:"",
        homePageLink:"https://www.transnet.net/Pages/Home.aspx",
        vacanciesLink:"https://transnettalentportal.csod.com/ux/ats/careersite/1/home?c=transnettalentportal&country=za",
        bursariesLink:"",
        facebookPage:"https://www.facebook.com/Transnet-SOC-Ltd-140849535994869/",
        linkedInPage:"",
        twitterPage:"http://twitter.com/follow_transnet"
    }
    ,
    {
        name:"Exxaro",
        logoImage:"",
        homePageLink:"https://www.exxaro.com/",
        vacanciesLink:"https://exxaro.erecruit.co/candidateapp/Jobs/Browse",
        bursariesLink:"",
        facebookPage:"https://www.facebook.com/ExxaroResources",
        linkedInPage:"https://www.linkedin.com/company/exxaro-resources",
        twitterPage:"https://twitter.com/exxaroresources"
    },
    {
        name:"Jindal - Mining",
        logoImage:"",
        homePageLink:"https://www.jindalafrica.com/",
        vacanciesLink:"https://www.jindalafrica.com/about-us/careers/",
        bursariesLink:"",
        facebookPage:"",
        linkedInPage:"",
        twitterPage:""
    }
    ,
    {
        name:"Tygerbrands",
        logoImage:"",
        homePageLink:"https://www.tigerbrands.com/",
        vacanciesLink:"https://hcxb.fa.em2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions?mode=location",
        bursariesLink:"",
        facebookPage:"https://www.facebook.com/TigerBrandsFMCG/",
        linkedInPage:"https://www.linkedin.com/company/tiger-brands/",
        twitterPage:"https://twitter.com/TigerBrands"
    }
    ,
    {
        name:"Cocal Colar",
        logoImage:"",
        homePageLink:"https://www.ccbsaco.com/",
        vacanciesLink:"https://ccba.erecruit.co/candidateapp/Jobs/Browse",
        bursariesLink:"",
        facebookPage:"https://www.facebook.com/CocaColaAfricaCom",
        linkedInPage:"https://za.linkedin.com/company/coca-cola-beverages-south-africa-ccbsa",
        twitterPage:""
    }
]