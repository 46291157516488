import PublicRoute from "./public-route";
import PrivateRoute from "./private-route";
import ProtectedRoute from "./protected-route";

export default function RoutesConfig()
{
    return(
        <>
          <PublicRoute/>
        </>
    )
}

